<template>
    <BT-Blade-Item
        bladeName="customer-consignment"
        :bladesData="bladesData"
        :changeToggle="changeToggle"
        :loadingMsg="loadingMsg"
        navigation="customer-consignment"
        :onCanDelete="item => item != null && item.stockConsignmentID == null"
        :onPullSuccessAsync="loadConsignment"
        :refreshToggle="refreshToggle"
        :saveToggle="saveToggle"
        title="Customer Consignment"
        :defaultBladeWidth="500">
        <template v-slot="{ item, data }">
            <v-row no-gutters>
                <BT-Snack v-model="msg" />
       
                <v-col cols="12">
                    <BT-Field-Company
                        v-if="!data.isNew"
                        :id="item.buyerID"
                        :title="item.buyer.companyName" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-model="item.departureLocation"
                        label="FROM" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-model="item.destinationLocation"
                        label="TO" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.consignmentNumber"
                        label="Consignment #"
                        :isEditing="data.isNew || (data.isEditing && item.stockConsignmentID == null)" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On"
                        :isEditing="data.isNew || (data.isEditing && item.stockConsignmentID == null)" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Number
                        v-model.number="item.orderWeight"
                        label="Order Weight (Kg)"
                        :isEditing="data.isNew || data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Number
                        v-model.number="item.stockWeight"
                        label="Stock Weight (Kg)"
                        :isEditing="data.isNew || data.isEditing" />
                </v-col>

                <v-col cols="6" sm="4">
                    <v-divider />
                    <v-subheader>Ready To Order Courier</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <BT-Btn
                            v-if="!item.isReadyToOrderCourier"
                            class="primary ma-1"
                            @click="ready(item)"
                            icon="mdi-check"
                            label="Ready" />
                        <v-card v-else flat class="success mx-1">
                            <v-card-text><v-icon small left>mdi-check</v-icon>Readied on {{ item.readyOn | toShortDateAndTime }}</v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <BT-Btn
                                    v-if="!item.isFulfilled"
                                    @click="unready(item)"
                                    leftIcon="mdi-undo"
                                    inline
                                    label="Undo"
                                    small
                                    text />
                            </v-card-actions>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>

                <v-col cols="6" sm="4">
                    <v-divider />
                    <v-subheader>Packed</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <BT-Btn
                            v-if="item.packedOn == null"
                            buttonClass="ma-1 primary"
                            @click="pack(item)"
                            icon="mdi-check"
                            label="Packed" />
                        <v-card v-else flat class="success mx-1">
                            <v-card-text><v-icon small left>mdi-check</v-icon>Packed on {{ item.packedOn | toShortDateAndTime }}</v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <BT-Btn
                                    v-if="!item.isFulfilled"
                                    @click="unpack(item)"
                                    leftIcon="mdi-undo"
                                    inline
                                    label="Undo"
                                    small
                                    text />
                            </v-card-actions>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>

                <v-col cols="6" sm="4">
                    <v-divider />
                    <v-subheader>Courier Request</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <v-row v-if="!item.isOrdered" no-gutters>
                            <BT-Btn
                                class="primary ma-1"
                                @click="openToggle = !openToggle"
                                icon="mdi-check"
                                label="Courier / Pickup" />
                        </v-row>
                        <v-card v-else flat class="success mx-1">
                            <v-card-text><v-icon small left>mdi-check</v-icon>Courier Requested</v-card-text>
                            <v-card-actions></v-card-actions>
                        </v-card>
                    </v-slide-x-transition>
                    
                </v-col>

                <v-col cols="12">
                    <BT-Table
                        :canRefresh="false"
                        class="mt-4"
                        :headers="[
                            { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', subtitle: 2 },
                            { text: 'Package', value: 'measurement', display: true, title: 1 },
                            { text: 'Size', value: 'size', display: true, subtitle: 1 }]"
                        hideActions
                        :items="item.packagedItems"
                        title="Packages">
                        <template v-slot:actions>
                            <BT-Packages-Dialog
                                v-model="item.packagedItems"
                                buttonClass="mr-3"
                                icon-only
                                small
                                @ok="packages => { savePackages(packages, item) }" />
                        </template>
                        <template v-slot:measurement="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName" />
                        </template>
                        <template v-slot:size="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                textFilter="toMeasurementLine" />
                        </template>
                    </BT-Table>
                </v-col>

                <v-col v-if="!item.isBuyerCourier" cols="12">
                    <BT-Preload
                        label="Show Courier Orders">
                        <v-subheader>Courier Orders</v-subheader>
                        <BT-Blade-Items
                            addBladeName="courier-order"
                            :canExportCSV="false"
                            hideActions
                            hideBladeHeader
                            hideHeader
                            navigation="courier-orders"
                            :params="{ includeDetails: false, consignmentID: item.id }"
                            :refreshToggle="courierOrderRefreshToggle"
                            showList
                            title="Consignments">
                            <template v-slot:listItem="{ deleteItem, item }">
                                <v-list-item-avatar>
                                    <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                                        <template v-slot:placeholder>
                                            <v-icon color="primary">mdi-truck</v-icon>
                                        </template>
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>CO# {{ item.courierOrderNumber }} | {{ item.seller.companyName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.isPickup">*Pickup</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span v-if="item.fulfilledOn != null">Fulfilled</span>
                                        <span v-else-if="item.isBilled">Billed</span>
                                        <span v-else-if="item.isPlanned">Movement Planned</span>
                                        <span v-else-if="item.isApproved === true">Approved</span>
                                        <span v-else-if="item.isApproved === false">Rejected</span>
                                        <span v-else-if="item.isApproved == null">Awaiting Supplier Approval</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn
                                        v-if="item.seller.isManagedByAnotherAccount"
                                        icon
                                        @click.stop="deleteItem(item)">
                                        <v-icon class="error--text">mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>

                <BT-Dialog
                    :getOnOpen="getDialogOrder"
                    hideButton
                    :item="item"
                    @ok="postCourierOrder"
                    :onCanOK="canSaveCourierOrder"
                    :openToggle="openToggle"
                    width="500"
                    label="New Courier Order"
                    :loadingMsg="loadingMsg">
                    <template v-slot="d">
                        <div v-if="d.item != null">
                            <BT-Select
                                v-if="isLengthyArray(d.item.departureOptions)"
                                :items="d.item.departureOptions"
                                itemValue="id">
                                <template v-slot:item="{ item }">
                                    {{ item | toLocationLine }}
                                </template>
                            </BT-Select>

                            <BT-Location 
                                v-else
                                :location="d.item.departureLocation"
                                :includeCompany="true"
                                :showAddress="false"
                                label="Depart From" />

                            <BT-Location
                                :location="d.item.destinationLocation"
                                :includeCompany="true"
                                label="Destination" />
                            
                            <BT-Date-Picker
                                v-model="d.item.dueDate"
                                label="Due Date" />

                            <div class="text-subtitle-1 mt-4">Who will be in charge of logistics?</div>

                            <v-row>
                                <v-col cols="6">
                                    <BT-Btn
                                        block
                                        :class="d.item.isSelfCourier == null ? 'primary' : (d.item.isSelfCourier == true ? 'success' : null)"
                                        icon="mdi-account-box"
                                        label="Me"
                                        @click="d.item.isSelfCourier = d.item.isSelfCourier == true ? null : true" />
                                </v-col>
                                <v-col cols="6">
                                    <BT-Btn
                                        block
                                        :class="d.item.isSelfCourier == null ? 'primary' : (d.item.isSelfCourier == false ? 'success' : null)"
                                        icon="mdi-account-box"
                                        label="Someone Else"
                                        @click="d.item.isSelfCourier = d.item.isSelfCourier == false ? null : false" />
                                </v-col>
                            </v-row>

                            <v-slide-x-transition group hide-on-leave>
                                
                                <div v-if="d.item.isSelfCourier == true" class="text-subtitle-1 mt-4" key="1">Will it be a pickup or delivery?</div>

                                <v-row v-if="d.item.isSelfCourier == true" key="2">
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.isPickup == null ? 'primary' : (d.item.isPickup == true ? 'success' : null)"
                                            icon="mdi-map-marker-up"
                                            label="Pickup"
                                            @click="d.item.isPickup = d.item.isPickup == true ? null : true" />
                                    </v-col>
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.isPickup == null ? 'primary' : (d.item.isPickup == false ? 'success' : null)"
                                            icon="mdi-truck-fast"
                                            label="Delivery"
                                            @click="d.item.isPickup = d.item.isPickup == false ? null : false" />
                                    </v-col>
                                </v-row>

                                <div v-if="d.item.isSelfCourier == true && d.item.isPickup == false"
                                    class="text-subtitle-1 mt-4"
                                    key="3">
                                    Do you want to use your default settings?
                                </div>

                                <v-row v-if="d.item.isSelfCourier == true && d.item.isPickup == false" key="4">
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.useDefaultSettings == null ? 'primary' : (d.item.useDefaultSettings == true ? 'success' : null)"
                                            icon="mdi-cog"
                                            label="Default"
                                            @click="d.item.useDefaultSettings = d.item.useDefaultSettings == true ? null : true" />
                                    </v-col>
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.useDefaultSettings == null ? 'primary' : (d.item.useDefaultSettings == false ? 'success' : null)"
                                            icon="mdi-wrench"
                                            label="Custom"
                                            @click="customRouteToggle = !customRouteToggle" />
                                    </v-col>
                                </v-row>

                                <div v-if="d.item.isSelfCourier == true && d.item.useDefaultSettings == false && isLengthyArray(d.item.plannedJunctures)" key="4.1" class="text-subtitle-1 mt-4">Junctures</div>

                                <BT-List-Endless
                                    v-if="d.item.isSelfCourier == true && d.item.useDefaultSettings == false && isLengthyArray(d.item.plannedJunctures)"
                                    :canSearch="false"
                                    :items="d.item.plannedJunctures"
                                    key="4.2">
                                    <template #default="li">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <BT-Entity
                                                    :itemValue="li.item.destinationLocationID"
                                                    navigation="public-locations"
                                                    single
                                                    textFilter="toFamiliarLocationLine"
                                                    useLocalCache />
                                            </v-list-item-title>
                                            <v-list-item-subtitle>{{ li.item.dueArrivalOn | toDayShortDate }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="mouse-over-show">
                                            <v-btn icon small @click="() => removeJuncture(item, li.index)">
                                                <v-icon class="error--text" small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </template>
                                </BT-List-Endless>
                                <div v-else-if="d.item.isSelfCourier == true && d.item.useDefaultSettings == false" class="text-subtitle-1 mt-4" key="4.3">
                                    *No consignment route will be created.
                                </div>

                                <BT-Select-List-Box
                                    v-if="d.item.isSelfCourier == false"
                                    isEditing
                                    isSelecting
                                    itemValue="sellerID"
                                    itemText="seller.companyName"
                                    label="Courier"
                                    maxHeight="250"
                                    navigation="couriers"
                                    v-model="d.item.sellerID" key="5" />

                                <div v-if="d.item.isSelfCourier == false && d.item.sellerID != null"
                                    class="text-subtitle-1 mt-4"
                                    key="5.1">
                                    Do you want the courier to pickup from your location?
                                </div>

                                <v-row v-if="d.item.isSelfCourier == false && d.item.sellerID != null" key="6">
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.preferStartPickup == null ? 'primary' : (d.item.preferStartPickup == true ? 'success' : null)"
                                            icon="mdi-map-marker-up"
                                            label="Please Pickup"
                                            @click="d.item.preferStartPickup = d.item.preferStartPickup == true ? null : true" />
                                    </v-col>
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.preferStartPickup == null ? 'primary' : (d.item.preferStartPickup == false ? 'success' : null)"
                                            icon="mdi-map-marker-down"
                                            label="I Will Drop Off"
                                            @click="d.item.preferStartPickup = d.item.preferStartPickup == false ? null : false" />
                                    </v-col>
                                </v-row>

                                <div v-if="d.item.isSelfCourier == false && d.item.sellerID != null && d.item.preferStartPickup != null"
                                    class="text-subtitle-1 mt-4"
                                    key="7">
                                    Do you want the customer to pickup from the courier?
                                </div>

                                <v-row v-if="d.item.isSelfCourier == false && d.item.sellerID != null && d.item.preferStartPickup != null" key="8">
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.preferEndDropoff == null ? 'primary' : (d.item.preferEndDropoff == true ? 'success' : null)"
                                            icon="mdi-map-marker-up"
                                            label="Pickup From Courier"
                                            @click="d.item.preferEndDropoff = d.item.preferEndDropoff == true ? null : true" />
                                    </v-col>
                                    <v-col cols="6">
                                        <BT-Btn
                                            block
                                            :class="d.item.preferEndDropoff == null ? 'primary' : (d.item.preferEndDropoff == false ? 'success' : null)"
                                            icon="mdi-map-marker-down"
                                            label="Drop Off To Customer"
                                            @click="d.item.preferEndDropoff = d.item.preferEndDropoff == false ? null : false" />
                                    </v-col>
                                </v-row>

                                <div v-if="d.item.isSelfCourier == false && d.item.sellerID != null && d.item.preferStartPickup != null && d.item.preferEndDropoff != null"
                                    class="text-subtitle-1 mt-4"
                                    key="9">
                                    Any message for the courier?
                                </div>

                                <v-textarea
                                    v-if="d.item.isSelfCourier == false && d.item.sellerID != null && d.item.preferStartPickup != null && d.item.preferEndDropoff != null"
                                    v-model="d.item.requesterMessage"
                                    label="Message"
                                    rows="4" key="10" />

                            </v-slide-x-transition>
                        </div>

                        <Juncture-Builder
                            :courierOrder="item"
                            :openToggle="customRouteToggle"
                            @ok="res => addCustomRoute(d.item, res)" />
                    </template>
                </BT-Dialog>
            </v-row>

            <v-overlay 
                :value="!data.isEditing && !data.isNew && item.isSourceChanged"
                absolute
                opacity="0.98">
                
                <v-container>
                    <v-list v-if="stockConsignment != null" class="transparent">
                        <v-slide-x-transition>
                            <v-list-item v-if="stockConsignment.isBuyerCourier != item.isBuyerCourier">
                                <v-list-item-content>
                                    <v-list-item-subtitle v-if="stockConsignment.isBuyerCourier">Customer Is Requesting To Organize Courier</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>Customer Is Requesting Supplier To Organize Courier</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>
                        
                        <v-divider v-if="stockConsignment.isBuyerCourier != item.isBuyerCourier" class="my-3" />
                        
                        <v-slide-x-transition>
                            <v-list-item v-if="stockConsignment.dueDate != item.dueDate">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Due Date</v-list-item-subtitle>
                                    <v-list-item-title>{{ stockConsignment.dueDate | toShortDate }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>
                        
                        <v-divider v-if="stockConsignment.dueDate != item.dueDate" class="my-3" />
                        
                        <v-slide-x-transition>
                            <v-list-item v-if="stockConsignment.consignmentNumber != item.consignmentNumber">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Consignment Number</v-list-item-subtitle>
                                    <v-list-item-title>#{{ stockConsignment.consignmentNumber }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-divider v-if="stockConsignment.consignmentNumber != item.consignmentNumber" class="my-3" />

                        <v-slide-x-transition>
                            <v-list-item v-if="stockConsignment.departureLocationID != item.departureLocationID">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Pickup Location</v-list-item-subtitle>
                                    <v-list-item-title>{{ stockConsignment.departureLocation | toLocationLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-divider v-if="stockConsignment.departureLocationID != item.departureLocationID" class="my-3" />

                        <v-slide-x-transition>
                            <v-list-item v-if="stockConsignment.destinationLocationID != item.destinationLocationID">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Destination</v-list-item-subtitle>
                                    <v-list-item-title>{{ stockConsignment.destinationLocation | toLocationLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-btn
                            class="primary"
                            small
                            block
                            @click="saveChanges(item)">Acknowledge And Save</v-btn>

                    </v-list>
                </v-container>
            </v-overlay>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Customer-Consignment-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTPackagesDialog: () => import('~components/BT-Packages-Dialog.vue'),
        BTLocation: () => import('~components/BT-Location.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTTable: () => import('~components/BT-Table.vue'),
        JunctureBuilder: () => import('~home/courier-orders/Juncture-Builder.vue')
    },
    data: function() {
        return {
            changeToggle: false,
            courierOrderRefreshToggle: false,
            customRouteToggle: false,
            loadingMsg: null,
            msg: null,
            openJunctureToggle: false,
            openToggle: false,
            possibleLocations: null,
            refreshToggle: false,
            saveToggle: false,
            stockConsignment: null,
            tabIndex: 0,
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        canSaveCourierOrder(obj) {
            if (obj == null) {
                return false;
            }

            if (obj.sellerID == null && obj.isSelfCourier == null) {
                return false;
            }

            if (obj.isSelfCourier == true && obj.isPickup == true) {
                return true;
            }

            if (obj.isSelfCourier == false && obj.sellerID != null && obj.preferStartPickup != null && obj.preferEndDropoff != null) {
                return true;
            }

            if (obj.isSelfCourier == true && obj.isPickup == false && obj.useDefaultSettings != null) {
                return true;
            }
            

            return false;
        },
        addCustomRoute(obj, { plannedJunctures, isPickupRequest }) {
            obj.isPickupRequest = isPickupRequest;
            obj.plannedJunctures = plannedJunctures;
            obj.useDefaultSettings = false;
        },
        removeJuncture(obj, index) {
            obj.plannedJunctures.splice(index, 1);
            
            if (this.isLengthyArray(obj.plannedJunctures)) {
                var depID = obj.departureLocationID;
                var i = 1;
                obj.plannedJunctures.forEach(pJun => {
                    pJun.departureLocationID = depID;
                    pJun.sortNumber = i;
                    i++;
                    depID = pJun.destinationLocationID;
                })
            }
        },
        async autoOrder(item, isPickup = null) {
            try {
                this.loadingMsg = 'Finding a courier...';

                var data = {
                    rowVersion: item.rowVersion,
                    id: item.id,
                    isReadyToOrderCourier: true,
                    isOrderCourierRequest: isPickup === true ? false : true,
                    isPickupRequest: isPickup
                }

                var res = await this.$BlitzIt.store.patch('customer-consignments', data);
                item.rowVersion = res.rowVersion;
                
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        getDialogOrder(item) {
            this.tabIndex = 0;

            return {
                sellerID: null,
                isSelfCourier: null,
                isPickup: null,
                preferStartPickup: null,
                preferEndDropoff: null,
                requesterMessage: null,
                courierConsignmentID: item.id,
                departureLocationID: item.departureLocationID,
                departureLocation: item.departureLocation,
                destinationLocationID: item.destinationLocationID,
                destinationLocation: item.destinationLocation,
                dueDate: item.dueDate,
                consignment: item,
                loading: false,
                useDefaultSettings: true,
                plannedJunctures: []
            };
        },
        async loadConsignment(consignment, refresh) { //, bladeData) {
            if (consignment != null && consignment.isSourceChanged === true) {
                this.stockConsignment = await this.$BlitzIt.store.get('stock-consignments', consignment.id, null, refresh);
            }

            return consignment;
        },
        async pack(item) {
            try {
                this.loadingMsg = 'Marking as packed';
                item.isPacked = true;
                var res = await this.$BlitzIt.store.patch('customer-consignments', item);
                item.rowVersion = res.rowVersion;
                item.packedOn = res.packedOn;
                this.changeToggle = !this.changeToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async postCourierOrder(item) {
            if ((item.isSelfCourier == false && item.sellerID != null) || item.isSelfCourier == true) {
                var postObj = {
                    sellerID: item.isSelfCourier == true ? this.companyAccountID() : item.sellerID,
                    requesterMessage: item.requesterMessage,
                    courierConsignmentID: item.courierConsignmentID,
                    requestedDepartureLocationID: item.departureLocationID,
                    requestedDestinationLocationID: item.destinationLocationID,
                    dueDate: item.dueDate,
                    preferStartPickup: item.preferStartPickup,
                    preferEndDropoff: item.preferEndDropoff,
                    isPickup: null,
                    isPlanRouteRequest: null,
                    isCreateMovementsRequest: null,
                    plannedJunctures: null
                };

                if (item.isSelfCourier == true) {
                    postObj.preferStartPickup = true;
                    postObj.preferEndDropoff = true;

                    if (item.isPickup == true) { //default to plan pickup
                        postObj.isPickup = item.isPickup;
                        postObj.isPlanRouteRequest = true;
                        postObj.isCreateMovementsRequest = true;
                    }
                    else {
                        postObj.isPickup = false;
                        if (item.useDefaultSettings == false) {
                            //customize here
                            if (item.plannedJunctures == null || item.plannedJunctures.length == 0) {
                                //no plan
                                postObj.isPlanRouteRequest = false;
                                postObj.isCreateMovementsRequest = false;
                            }
                            else {
                                postObj.isPlanRouteRequest = true;
                                postObj.isCreateMovementsRequest = true;
                                postObj.plannedJunctures = item.plannedJunctures;
                            }
                        }
                    }
                }
                else {
                    postObj.isPickup = false;
                }

                try {
                    this.loadingMsg = 'Creating Courier Order';
                    await this.$BlitzIt.store.post('courier-orders', postObj);
                    this.courierOrderRefreshToggle = !this.courierOrderRefreshToggle;
                    this.refreshToggle = !this.refreshToggle;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        async ready(item) {
            try {
                this.loadingMsg = 'Marking as packed';
                
                item.isReadyToOrderCourier = true;
                item.isReadyRequest = true;
                
                var res = await this.$BlitzIt.store.patch('customer-consignments', item);
                item.rowVersion = res.rowVersion;
                item.readyOn = res.readyOn;
                this.changeToggle = !this.changeToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async savePackages(packages, item) {
            try {
                this.loadingMsg = 'Marking as packed';
                item.packagedItems = packages;
                console.log(packages);
                var res = await this.$BlitzIt.store.patch('customer-consignments', item);
                item.rowVersion = res.rowVersion;
                this.changeToggle = !this.changeToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        saveChanges(consignment) {
            if (this.stockConsignment != null) {
                consignment.departureLocationID = this.stockConsignment.departureLocationID;
                consignment.departureLocation = this.stockConsignment.departureLocation;
                consignment.destinationLocationID = this.stockConsignment.destinationLocationID;
                consignment.destinationLocation = this.stockConsignment.destinationLocation;
                consignment.isBuyerCourier = this.stockConsignment.isBuyerCourier;
                consignment.consignmentNumber = this.stockConsignment.consignmentNumber;
                consignment.dueDate = this.stockConsignment.dueDate;
                consignment.isSourceChanged = false;

                this.saveToggle = !this.saveToggle;
            }
        },
        async unpack(item) {
            try {
                this.loadingMsg = 'Marking as packed';
                item.isPacked = false;
                var res = await this.$BlitzIt.store.patch('customer-consignments', item);
                item.rowVersion = res.rowVersion;
                item.packedOn = res.packedOn;
                this.changeToggle = !this.changeToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async unready(item) {
            try {
                this.loadingMsg = 'Marking as packed';
                
                item.isReadyToOrderCourier = false;
                item.isReadyRequest = false;
                
                var res = await this.$BlitzIt.store.patch('customer-consignments', item);
                item.rowVersion = res.rowVersion;
                item.readyOn = res.readyOn;
                this.changeToggle = !this.changeToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>